module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"r57C1YEfq7faDkSPjRN4oK838rbgdIc0","devKey":"UZ6WfM74WHrwsuXmDd2mymiGnF0c1BE0","trackPage":true,"trackPageDelay":50},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chord","short_name":"Chord","start_url":"/","background_color":"#232323","theme_color":"#232323","icon":"static/Favicon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d24160360ae0aa4aeff7454f02a7a3b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":80,"linkImagesToOriginal":false,"maxWidth":2048,"disableBgImageOnAlpha":true,"backgroundColor":"none","showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/buildhome/repo/src/cms/cms.ts"},
    }]
